import React, { FunctionComponent, useContext, useEffect, useState } from "react";
import SwiperCore, { Lazy, Swiper as SwiperJs } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import { ArrowRight } from "../../images/icons/arrow-right";
import { ArrowLeft } from "../../images/icons/arrow-left";
import { useInView } from "react-intersection-observer";
import { ReactComponent as ArrowPrevMobile } from "../../images/icons/arrow-left-mobile.svg";
import { ReactComponent as ArrowNextMobile } from "../../images/icons/arrow-right-mobile.svg";
import { ImageInterface, Slug } from "../../types/SanityTypes";
// import Accessibility from "../Accessibility";
import Slide from "../Slide";
import "./styles.scss";
import "swiper/css";
import "swiper/css/navigation";
// import "swiper/css/pagination";

import { LocalizedContext } from "../../services/LocalizedContextService";
import { event12 } from "../../analytics/event12";
import { event66 } from "../../analytics/event66";
import WhereToBuyWidget from "../WhereToBuy";

interface ProductSliderV3Interface {
  _id: string;
  _rawImages: ImageInterface[];
  subBrand: {
    name: string;
  };
  displayReviews: boolean;
  name: string;
  paUPC: string;
  slug: {
    current: string;
  };
  paSmartProductId: string;
  variant?: {
    primaryColour?: {
      hex?: string;
    };
  };
  // For Preview
  images?: ImageInterface[];
  sliderType: {
    name: string;
    description: string;
  };
}

type SliderContentUnionType = ProductSliderV3Interface[] | ImageInterface[];

export interface SliderBlockV3Interface {
  data: SliderContentUnionType;
  textColor?: string;
  type: string;
  variation?: string;
  _rawImage?: ImageInterface;
  url?: string;
  title?: string;
}

SwiperCore.use([Lazy]);

const SliderBlockV3: FunctionComponent<SliderBlockV3Interface> = ({ data, type }) => {
  const { sanityAccessibility, sanitySiteSettings } = useContext(LocalizedContext);
  const { sanityProductLandingPage, sanitySearch } = useContext(LocalizedContext);
  const [width, setWidth] = useState(992);
  const { previous, next } = sanityAccessibility || {};

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: "5px 0px"
  });

  // const [swiper, setSwiper] = useState(new SwiperJs(""));

  // const swiperNext = () => {
  //   if (swiper) {
  //     swiper.slideNext();
  //     event66(next ? next : "slider next");
  //   }
  // };

  // const swiperPrev = () => {
  //   if (swiper) {
  //     swiper.slidePrev();
  //     event66(previous ? previous : "slider previous");
  //   }
  // };

  const toggleRightArrowChange = () => {
    return width >= 992 ? <ArrowRight /> : <ArrowNextMobile />;
  };

  const toggleLeftArrowChange = () => {
    return width >= 992 ? <ArrowLeft /> : <ArrowPrevMobile />;
  };

  const setBreakPoints = {
    375: {
      initialSlide: 0,
      spaceBetween: 20,
      slidesPerView: 2,
      scrollbar: {
        draggable: true
      }
    },
    768: {
      initialSlide: 0,
      spaceBetween: 30,
      slidesPerView: 3,
      scrollbar: {
        draggable: true
      }
    }
  };

  const renderSlides = () => {
    if (type === "productV2") {
      return (data as ProductSliderV3Interface[]).map((item: ProductSliderV3Interface, index: number) => {
        const imageArray = item?._rawImages || item?.images || [];
        const renderImage = imageArray.length < 1 ? (sanitySearch?._rawPlaceholder as ImageInterface) : imageArray[0];
        const onClick = () =>
          event12(
            item._id,
            item.name ? item.name : "",
            item.subBrand ? item.subBrand.name : "",
            sanitySiteSettings.brandName,
            index,
            "Product Slider"
          );
        return (
          <React.Fragment key={`${index}`}>
            <SwiperSlide className="swiper-slide" key={index} data-testid="product-slider">
              {!item ? null : (
                <Slide
                  key={index}
                  itemPosition={`${index}`}
                  link={`${sanityProductLandingPage?.slug.current}/${item.slug?.current}`}
                  image={renderImage}
                  heading={item.name ? item.name : ""}
                  inView={inView}
                  id={item._id}
                  upc={item.paUPC || item.paSmartProductId}
                  type="productV2"
                  displayReviews={false}
                  onClick={onClick}
                  subBrand={item.subBrand ? item.subBrand.name : ""}
                />
              )}
            </SwiperSlide>
          </React.Fragment>
        );
      });
    }
  };

  return (
    <div ref={ref} className="slider-block-v3" data-testid="slider-block-v3">
      <WhereToBuyWidget />
      <div className="slider-size">
        <div className="exo-swiper-wrapper" data-testid="swiperv3">
          <Swiper
            key={type}
            navigation={true}
            speed={700}
            lazy={true}
            preloadImages={true}
            modules={[Pagination, Navigation]}
            breakpoints={setBreakPoints}
            // onSwiper={setSwiper}
          >
            {renderSlides()}
          </Swiper>
        </div>
        {/* <>
          <span className="slider-button-container slider-prev" data-testid="prev-col-v3">
            <button data-anchor-link className="prev-button" type="button" onClick={swiperPrev} data-testid="prev-button">
              {toggleLeftArrowChange()}
              <Accessibility location={previous} />
            </button>
          </span>
          <span className="slider-button-container slider-next" data-testid="next-col-v3">
            <button data-anchor-link className="next-button" type="button" onClick={swiperNext} data-testid="next-button">
              {toggleRightArrowChange()}
              <Accessibility location={next} />
            </button>
          </span>
        </> */}
      </div>
    </div>
  );
};
export default SliderBlockV3;
