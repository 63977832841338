import React, { FunctionComponent, useContext, useEffect } from "react";
import { ImageInterface } from "../../types/SanityTypes";
import SliderBlockV3 from "../SliderBlockV3";
import "./styles.scss";
import { Container } from "react-bootstrap";
import { event11 } from "../../analytics/event11";
import { LocalizedContext } from "../../services/LocalizedContextService";
import ProductRatingsAndReviewScript from "../ProductRatingsAndReviewsScript";
import { Link } from "../Link";

export interface ProductSliderV3Interface {
  heading?: string;
  bgColor?: {
    value: string;
  };
  template?: string;
  content: {
    _id: string;
    _rawImages: ImageInterface[];
    title: string;
    url: string;
    _rawImage: ImageInterface;
    SubBrand: {
      name: string;
    };
    variant?: {
      primaryColor?: {
        hex?: string;
      };
    };
    name: string;
    displayReviews: boolean;
    slug: {
      current: string;
    };
    paUPC: string;
    paSmartProductId: string;
    // For Preview
    images?: ImageInterface[];
  }[];
  theme: {
    name: string;
  };
  ctaLink?: any;
}

const ProductSliderV3: FunctionComponent<ProductSliderV3Interface> = props => {
  const { sanitySiteSettings, sanityProductLandingPageV2 } = useContext(LocalizedContext);
  const { heading, bgColor, content, template = "productV2", ctaLink, theme } = props;

  useEffect(() => {
    event11(content, sanitySiteSettings?.brandName, "Product Slider");
  }, [content, sanitySiteSettings?.brandName]);

  const createThemeClassName = (className: string) => {
    return className ? `theme--${className?.replace(" ", "").toLocaleLowerCase()}` : "";
  };

  const getCtaURL = () => {
    if (ctaLink?.__typename === "SanityProductLinePageV2") {
      return `/${sanityProductLandingPageV2?.slug.current}/${ctaLink.slug?.current}/`;
    } else {
      return `/${ctaLink?.slug?.current}/`;
    }
  };

  return (
    <section
      className={`rx-product-slider-v3 ${createThemeClassName(theme?.name)}`}
      data-testid="product-slider-v3"
      style={{ backgroundColor: bgColor?.value }}
    >
      <Container fluid>
        {heading && (
          <h2 className="rx-product-slider-v3-title">
            <span className={`rx-theme-unlimited ${createThemeClassName(theme?.name)}`}>{heading.split("-")[0]}</span>
            <span>{heading.split("-")[1]}</span>
          </h2>
        )}
        {content && <SliderBlockV3 data={content as ProductSliderV3Interface["content"]} type={template} />}

        {ctaLink && (
          <Link
            className="rx-product-slider-v3--cta"
            _id={""}
            to={`${getCtaURL() || "/products/unlimited-by-degree/"}`}
            data-analytics-event4=""
          >
            Browse full range
          </Link>
        )}
      </Container>
      <ProductRatingsAndReviewScript />
    </section>
  );
};

export default ProductSliderV3;
